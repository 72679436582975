//spell-checker: disable
import efficiencyImg from "assets/images/home/efficiency.png";
import launchingImg from "assets/images/home/launching.png";
import usabilityImg from "assets/images/home/usability.png";
import analyticsImg from "assets/images/home/analytics.svg";
import efficiencyImgLow from "assets/images/home/efficiency-min.png";
import launchingImgLow from "assets/images/home/launching-min.png";
import usabilityImgLow from "assets/images/home/usability-min.png";

import nboaLogo from "assets/images/customers/nboa_logo.png";
import avmaLogo from "assets/images/customers/avma_logo.svg";
import taaoLogo from "assets/images/customers/taao_logo.png";
import meccLogo from "assets/images/customers/mecc_logo.png";
import njsopLogo from "assets/images/customers/njsop_logo.jpeg";
import wmccaiLogo from "assets/images/customers/wmccai_logo.png";
import iaapaLogo from "assets/images/customers/iappa_logo.jpeg";
import aemLogo from "assets/images/customers/aem_logo.jpeg";
import aimcalLogo from "assets/images/customers/aimcal_logo.jpeg";
import aaoeLogo from "assets/images/customers/aaoe_logo.png";
import achneLogo from "assets/images/customers/achne_logo.png";
import arheLogo from "assets/images/customers/arhe_logo.jpg";
import ataalawLogo from "assets/images/customers/ataalaw_logo.jpg";
import americanhortLogo from "assets/images/customers/americanhort_logo.png";
import affinityceLogo from "assets/images/customers/affinityce_logo.png";
import mrcLogo from "assets/images/customers/mrc_logo.png";
import lmaLogo from "assets/images/customers/lma_logo.png";

import nboaPhoto from "assets/images/customers/nboa_photo.jpg";
import avmaPhoto from "assets/images/customers/avma_photo.jpg";
import taaoPhoto from "assets/images/customers/taao_photo.png";
import meccPhoto from "assets/images/customers/mecc_photo.jpeg";
import njsopPhoto from "assets/images/customers/njsop_photo.jpeg";
import wmccaiPhoto from "assets/images/customers/wmccai_photo.jpeg";
import iaapaPhoto from "assets/images/customers/iaapa_photo.png";
import aemPhoto from "assets/images/customers/aem_photo.jpeg";
import aimcalPhoto from "assets/images/customers/aimcal_photo.jpeg";
import aaoePhoto from "assets/images/customers/aaoe_photo.png";
import achnePhoto from "assets/images/customers/achne_photo.png";
import arhePhoto from "assets/images/customers/arhe_photo.png";
import ataalawPhoto from "assets/images/customers/ataalaw_photo.png";
import americanhortPhoto from "assets/images/customers/americanhort_photo.png";
import affinitycePhoto from "assets/images/customers/affinityce_photo.png";
import mrcPhoto from "assets/images/customers/mrc_photo.png";
import lmaPhoto from "assets/images/customers/lma_photo.png";

export const clientsData = [
  {
    logo: iaapaLogo,
    image: iaapaPhoto,
    body: "Working with SlideSpiel was a very cost effective and efficient process that allowed us to deploy a full, and robust virtual event in 8 short weeks. The platform backend is intuitive, and easy to use which allowed us to get to market so quickly.",
    author: "Reno Deschaine",
    title: "Vice President, Global Education and Member Services",
    org: "The International Association of Amusement Parks and Attractions",
    link: "iaapa",
    scale: 1.2
  }, {
    logo: nboaLogo,
    image: nboaPhoto,
    body: "SlideSpiel always knocks it out of the park recording our Annual Meeting. They’re extremely affordable and easy to work with. Their value add far surpasses the cost of their services. We’ve worked with other recording providers, and SlideSpiel excels on quality. If you’re looking to record a live event, SlideSpiel is the way to go.",
    author: "Melissa Zinder",
    title: "Senior Manager, Marketing and Member Engagement",
    org: "National Business Officers Association",
    link: "nboa",
    scale: 1.2
  }, {
    logo: avmaLogo,
    image: avmaPhoto,
    body: "AVMA has worked with SlideSpiel over the last four years. They are easy to work with and very accommodating. They offer different opportunities for recording that allow us to make it work for our needs. Their pricing is very reasonable and their platform is easy to use. I would highly recommend using SlideSpiel for any of your recording needs.",
    author: "Amber Auge",
    title: "Continuing Education Manager | Convention & Meeting Planning Division",
    org: "American Veterinary Medical Association",
    link: "avma"
  }, {
    logo: taaoLogo,
    image: taaoPhoto,
    body: `TAAO just completed our 83rd Annual Conference. This was the 2nd year we have partnered with SlideSpiel for recording the conference sessions. Their team is affordable, professional, reliable, experienced, easy to work with and they demonstrate a willingness to go the extra mile to get the job done. They recorded 29 sessions without a single issue. They house the recordings on the website. I will use SlideSpiel again and again! Great Job!`,
    author: "Zelda Cryar",
    title: "Executive Director",
    org: "Texas Association of Assessing Officers",
    link: "taao"
  }, {
    logo: aemLogo,
    image: aemPhoto,
    body: "As an event and conference director I would not hesitate to recommend SlideSpiel. The team was easy to work with and they could quickly pivot with us onsite when schedules changed or programs were adjusted. We received our quality product files in a timely fashion and there was never a lapse in communication.",
    author: "Brooke Konopacki",
    title: "Director, Education Programs",
    org: "Association of Equipment Manufacturers",
    link: "aem",
    scale: 0.9
  }, {
    logo: meccLogo,
    image: meccPhoto,
    body: "Our experience with SlideSpiel has been absolutely fabulous. Vova and his team are highly professional, reliable and with excellent outcome. Their response time and ability to accommodate to the unique circumstances of our events is highly appreciated.",
    author: "Teri Valls",
    title: "Executive Director",
    org: "Meetings Events Conference Coordinators",
    link: "mecc",
    scale: 1.5
  }, {
    logo: njsopLogo,
    image: njsopPhoto,
    body: "Our company has been utilizing Slide Spiel to manage our online education portal since 2019.  In addition, we have hired them to host a few of our live webinars and to also record sessions on-site at our live continuing education events.  We are satisfied with their service and expertise, as well as their ability to quickly identify and address our specific needs.",
    author: "Sharon L. Kais",
    title: "Associate Executive Director",
    org: "New Jersey Society of Optometric Physicians",
    link: "njsop"
  }, {
    logo: wmccaiLogo,
    image: wmccaiPhoto,
    body: "Building an on-demand video library website for our membership seemed like a daunting task . We needed to design a visually appealing, user-friendly, easily updated site on which our members could seamlessly purchase, view and receive credit for courses.  SlideSpiel helped us do just that at a reasonable cost!  Their staff has been very responsive and helpful with any issues.  We have a clean, well-maintained, branded site that we are very proud of – thank you!",
    author: "Suzanne White",
    title: "Educational Programs",
    org: "Washington Metropolitan Chapter Community Associations Institute",
    link: "wmccai",
    scale: 1.1
  }, {
    logo: aimcalLogo,
    image: aimcalPhoto,
    body: "SlideSpiel has proven to be an excellent resource for creating and hosting video content for our virtual events. With an easy to use platform and extremely fast technical support, SlideSpiel is a reliable and cost effective solution for your video content needs.",
    author: "Kevin Lifsey",
    title: "Web Developer",
    org: "The Association of International Metallizers, Coaters and Laminators",
    link: "aimcal",
    scale: 0.7
  },{
    logo: aaoeLogo,
    image: aaoePhoto,
    body: "SlideSpiel has recorded both the general and concurrent sessions at our Annual Conference for the past several years. They has been easy to work with and consistently provide a quality product. Our onsite experience has been excellent as well. Session recordings are provided quickly so that we can use them in our LMS.",
    author: "Vicki Sprague",
    title: "Chief Integration Officer",
    org: "American Alliance of Orthopaedic Executives",
    link: "aaoe",
    scale: 1
  },{
    logo: achneLogo,
    image: achnePhoto,
    body: "SlideSpiel has been an invaluable partner for our Annual Institute. Their dedication to understanding our specific needs is unparalleled. From project conception to execution, the SlideSpiel demonstrates a commitment to excellence. Their onsite videographers are top-notch professionals who are a pleasure to work with. We are thrilled with the quality of the video recordings and the overall experience.",
    author: "Roxy West",
    title: "Executive Director",
    org: "Association of Community Health Nursing Educators",
    link: "achne",
    scale: 1.2
  },{
    logo: arheLogo,
    image: arhePhoto,
    body: "ARHE utilized SlideSpiel’s services to record 80 hours of content over 4 days, and we were impressed with the quick turnaround, friendly service, and high quality of the recordings. The SlideSpiel content management system was easy to learn and operate. Anytime we had questions, the team at SlideSpiel were responsive, knowledgeable, and kind, ensuring that our questions were answered in a timely manner. On-site staff went above and beyond to help ensure that concurrent sessions were all recorded without issue.",
    author: "Mack Park",
    title: "Membership and Outreach Manager",
    org: "Association of Recovery in Higher Education",
    link: "arhe",
    scale: 1.5
  },{
    logo: ataalawLogo,
    image: ataalawPhoto,
    body: "I have used many videography services for legal conferences for 18 years. This is the first time we have used SlideSpiel for our annual symposium. I was thoroughly impressed. Videographers and techs were knowledgeable, well trained, adaptable and polite. The preplanning was well ahead of time and extremely thorough. SlideSpiel is more economical than services I’ve used before I’m definitely using them for our programs moving forward.",
    author: "Lori Tepper",
    title: "Executive Director",
    org: "Academy of Truck Accident Attorneys",
    link: "ataalaw",
    scale: 0.8
  },{
    logo: americanhortLogo,
    image: americanhortPhoto,
    body: "In our first year of utilizing SlideSpiel for recording live sessions the experience has been very positive. What truly stood out was the responsiveness of their CEO, who demonstrated a genuine commitment to our success. Their dedication to addressing our unique needs was impressive. This allowed us to provide our audience with a top-notch on-demand experience.",
    author: "Rachel Rawls",
    title: "Education Program Manager",
    org: "AmericanHort",
    link: "americanhort",
    scale: 1.5
  },{
    logo: affinityceLogo,
    image: affinitycePhoto,
    body: "We have worked with SlideSpiel to assist us with recording, streaming, and coordinating virtual speakers in Q&A sessions. Their recordings are high quality and significantly better than any of the other (4 or 5) companies we have used. Their onsite technicians are knowledgeable, professional, and problem solvers.",
    author: "George Vuturo",
    title: "Managing Partner",
    org: "AffinityCE",
    link: "affinityce",
    scale: 1.1
  },{
    logo: mrcLogo,
    image: mrcPhoto,
    body: "We engaged SlideSpiel to record sessions at our annual conference in Vegas.  They were cost effective and easy to work with.  I appreciated their ability to quickly adjust when needed, and the fast turnaround time.  We would definitely consider using SlideSpiel again at future events.",
    author: "Michael DeSantis",
    title: "Principal Project Manager",
    org: "Merchant Risk Council",
    link: "mrc",
    scale: 1
  },{
    logo: lmaLogo,
    image: lmaPhoto,
    body: "Slidespiel has been a Godsend for our company.  They're responsive, responsible and conscientious. Plus, their prices made it possible for us to record lengthy conferences without overtaxing our staff. I'd write more, but then the world would know about our \"secret weapon!\"  Best new production provider we've worked with in many many years! Highly recommend.",
    author: "Herb Holland",
    title: "IT Director",
    org: "Lutine Management Associates",
    link: "lma",
    scale: 1.3
  },
  // {
  //   logo: null,
  //   image: null,
  //   body: "",
  //   author: "",
  //   title: "",
  //   org: "",
  //   link: "null",
  //   scale: 1
  // },
];

export const servicesDetails = [
  {
    title: "Cost Efficiency",
    subtitle: "Cost efficient. No need to ship and rent expensive equipment",
    img: efficiencyImg,
    imgLow: efficiencyImgLow,
    gaEvent: "click_learn_more_cost_efficiency",
    text: `Traditional content-capturing companies rely on expensive hardware operated by multiple technicians.

We leverage our software solution that cuts the cost by 20%-40% by eliminating equipment rental & shipment costs, significantly reducing the number of on-site technicians.

It integrates onsite computers, basic AV equipment, and speaker presentations into a smart ecosystem that can be managed from the tech table or even remotely from our office. `
  },
  {
    title: "Quick Turnaround",
    subtitle: "We are proud to be the only company that can deliver edited recording files minutes after the session ends",
    img: launchingImg,
    imgLow: launchingImgLow,
    gaEvent: "click_learn_more_quick_turnaround",
    text: `We are proud of our ability to deliver edited recording files within minutes after each session ends.

Our software syncs slideshow presentations with a speaker’s voice and video in real time. In the background it uploads the file to our servers, where it is automatically cleaned and by AI and, finally, polished by our video editors.

Optionally, we set up an online learning website to house the content and auto-generate continuing education certificates based on activity tracking against specific metrics. `
  },
  {
    title: "Higher Quality",
    subtitle: "Higher quality recording with less staff due to remote quality control",
    img: usabilityImg,
    imgLow: usabilityImgLow,
    gaEvent: "click_learn_more_higher_quality",
    text: `Inevitably, unexpected problems will arise which is why quality control practices are so critical. Traditional methods require an on-site technician to monitor the recording quality in real-time. But having a dedicated specialist in every room is expensive. And floating technicians who monitor multiple rooms jeopardize the quality of the final product because their attention is fragmented.

Our platform allows us to monitor quality from our office using our remote control tools and resolve simple issues immediately while alerting on-site crew for complicated ones. This process is more efficient, costs significantly less than traditional methods, and is easy to track, scale, and manage.`
  },
  {
    title: "Reliable Virtual Conferences",
    subtitle: "Seamless / Reliable Virtual (& Hybrid) Conferences / Production",
    img: analyticsImg,
    imgLow: analyticsImg,
    gaEvent: "click_learn_more_reliable_virtual_conferences",
    text: `Human error or equipment malfunction contributes to most virtual or hybrid conference issues. Yet our multilevel quality control system mitigates this risk even before the speaker goes live. We have implemented a 30-second automated test for every speaker to ensure everything is working properly. We also follow up directly with anyone likely to experience an issue during a live event. 
    
On the day of the event, speakers join a virtual “backstage” or “green room” to interact with our production team, verify audio and video quality, and provide any as-needed instructions. Our proprietary software allows us to resolve common equipment problems like selecting the correct microphone and playback device which is an added convenience for non-tech-savvy speakers. Once finished, we remotely activate the live session without any further action from the speaker. 
    
Our pre-recording service is resilient to video freezes and distortions that could result from slow internet connection on the speaker end. Our platform saves the recording file on the user's computer and then uploads it on our servers in the highest quality.`
  },
];

export const solutionsTableData = {
  header: ["", "Live streaming", "Conference recording", "Courses from recordings", "Continues production"],
  rows: [
    ["Targeted Segment", "Membership & Followers", "Regular Attendees", "Wider Audience", "Wider Audience"],
    ["Revenue opportunity", "Medium", "Low", "High", "Medium"],
    ["Monetary investment", "High", "Medium", "High", "Low"],
    ["Time investment", "Medium", "Low", "High", "High"],
    ["Marketing", "Easy", "Medium", "Hard", "Hard"],
  ]
}